const handleOpenModal = (event) => {
  console.log(event);
  console.log(
    event.target.closest(".brand__item").children[1].children[1].innerText
  );
  let brandItem = event.target.closest(".brand__item"),
    imgSrc = brandItem.children[0].currentSrc,
    title = brandItem.children[1].children[0].innerText,
    detail = brandItem.children[1].children[2].innerText;
    
  Swal.fire({
    showCloseButton: true,
    showConfirmButton: false,
    width: 700,
    html:
      "<div class='py-4 d-sm-flex modal'><div class='col-sm-5 px-0'><img src=" +
      imgSrc +
      " alt='' /></div><div class='text-sm-left ml-sm-5 modal__detail'><h4 class='mt-4 mt-sm-0 mb-3 title'>" +
      title +
      "</h4><p class='detail' style='word-break: break-word;white-space: pre-line;'>" +
      detail +
      "</p></div></div>",
  });
};
